import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { Helmet } from 'react-helmet'
import Modal from 'react-modal'

import AlertPopup from '../../components/alert'
import CookieConsentPopup from '../../components/cookie-consent'
import Offline from '../../components/offline'
import Intercom from '../../components/intercom'
import Raven from '../../components/raven'
import appleTouchIcon from '../../img/favicon/apple-touch-icon.png'
import favicon32 from '../../img/favicon/favicon-32x32.png'
import favicon16 from '../../img/favicon/favicon-16x16.png'
import safariTab from '../../img/favicon/safari-pinned-tab.svg'
import { MAIN_TITLE } from '../../config/settings'
import sessionStorage from '../../utils/session-storage'
import ModalConfirm from '../../components/modal-confirm'

import styles from './styles.css'

Modal.setAppElement('#reactModal')

export const VideoPlayerContext = React.createContext({
  videoPlayerNode: null,
  setVideoPlayerNode: () => {},
})

class App extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({
        offset: PropTypes.string,
      }),
    }).isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  state = {
    videoPlayerNode: null,
  }

  componentDidMount() {
    // Making sure mock sessions fully expire when closing the application
    // This is necessary so we don't accidentally boot intercom and modify
    // other persistent user preferences
    if (typeof window !== 'undefined' && sessionStorage.getItem('admin_login')) {
      window.addEventListener('unload', () => {
        document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
        document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
      })
    }
  }

  componentDidUpdate(prevProps) {
    const nextRoutes = this.props.routes
    if (
      nextRoutes[nextRoutes.length - 1].skipScrollUp ||
      prevProps.routes[prevProps.routes.length - 1].skipScrollUp
    ) {
      return
    }

    const prevPathname = prevProps.location.pathname
    const pathname = this.props.location.pathname
    const prevOffset = prevProps.location.query.offset
    const offset = this.props.location.query.offset

    const skipScrollUpOnOffsetChange = nextRoutes[nextRoutes.length - 1].skipScrollUpOnOffset

    if (skipScrollUpOnOffsetChange && pathname === prevPathname && offset !== prevOffset) {
      return
    }

    // not sure if we still need this next block, it seems to
    // have been replaced by the skipScrollUp prop on route declaration
    if (
      pathname !== prevProps.location.pathname &&
      // ugly way to prevent scrolling up when opening a modal,
      // in the future there should be some programatic way to
      // know when a route is a modal
      !pathname.includes('/add-note/') &&
      !prevProps.location.pathname.includes('/add-note/')
    ) {
      window.scrollTo(0, 0)
    }
    if (
      pathname !== '/offers/available' &&
      this.props.location.query.offset !== prevProps.location.query.offset
    ) {
      window.scrollTo(0, 0)
    }
  }

  setVideoPlayerNode = (node) => {
    this.setState({
      videoPlayerNode: node,
    })
  }

  dismissVideoPlayer = () => {
    this.setVideoPlayerNode(null)
  }

  videoPlayer = {
    videoPlayerNode: this.state.videoPlayerNode,
    setVideoPlayerNode: this.setVideoPlayerNode,
  }

  render() {
    const { videoPlayerNode } = this.state
    return (
      <div className={styles.page}>
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
          <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
          <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />
          <link rel="mask-icon" href={safariTab.url} color="#0f171f" />
          <meta name="theme-color" content="#ffffff" />
          <title>{MAIN_TITLE}</title>
          <meta
            name="description"
            content="Level-up your workflow and relationship management with authentication, distribution, tracking, reporting and more."
          />
        </Helmet>

        <VideoPlayerContext.Provider value={this.videoPlayer}>
          {this.props.children}
        </VideoPlayerContext.Provider>
        <ModalConfirm
          open={!!videoPlayerNode}
          hideConfirm
          cancelText="dismiss"
          onCancel={this.dismissVideoPlayer}
          onConfirm={this.dismissVideoPlayer}
          onRequestClose={this.dismissVideoPlayer}
          footerClassName={styles.videoPlayerFooter}
          className={styles.videoPlayer}
        >
          {videoPlayerNode || <span />}
        </ModalConfirm>

        <ReactTooltip class={styles.toolTip} multiline place="top" effect="solid" />
        <AlertPopup />
        <CookieConsentPopup />
        <Intercom />
        <Offline />
        <Raven />
      </div>
    )
  }
}

export default App
