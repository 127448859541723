import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

class UpgradeAccount extends Component {
  static propTypes = {
    className: PropTypes.string,
  }

  static defaultProps = {
    className: undefined,
  }

  render() {
    const { className } = this.props

    return (
      <div className={classnames(styles.container, className)}>
        <section className={styles.shutdown}>
          <p>
            Alas, Woovit will shut down at 6 AM Eastern on Friday, December 20, 2024. Thanks to all
            the publishers and creators who have made Woovit possible the last seven years.
          </p>
          <p>
            Reading this after time? We’ve archived data from publishers. We’re still available at
            hello@woovit.com.
          </p>
        </section>
      </div>
    )
  }
}

export default UpgradeAccount
