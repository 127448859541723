import PropTypes from 'prop-types'
import React from 'react'

import HeaderPublic from '../../components/header-public'
import Footer from '../../components/footer'
import ErrorBoundary from '../../components/error-boundary'

import styles from './styles.css'

const AppPublic = ({ children }) => (
  <div className={styles.container}>
    <HeaderPublic />
    <section className={styles.shutdown}>
      Alas, Woovit will shut down at 6 AM Eastern on Friday, December 20, 2024. Thanks to all the
      publishers and creators who have made Woovit possible the last seven years.
    </section>
    <div className={styles.content}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
    <Footer />
  </div>
)

AppPublic.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppPublic
