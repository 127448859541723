import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ImmutableProptypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { browserHistory } from 'react-router'

import Footer from 'components/footer'
import Button from 'components/button'
import Header from 'components/header'
import ModalConfirm from 'components/modal-confirm'
import Loading from 'components/piece-loading'
import Field from 'components/piece-field'
import ErrorBoundary from 'components/error-boundary'
import { Provider } from 'components/provider-icon'
import {
  getUserByToken,
  acceptTerms,
  updateUserAccount,
  getOrganizationInvitations,
  acceptOrganizationInvitation,
  removeOrganizationInvitation,
  updateNewPreferences,
  getPreferences,
  ACCEPT_ORGANIZATION_INVITATION,
  REMOVE_ORGANIZATION_INVITATION,
} from 'modules/user/actions'
import { KIND } from 'config/user-settings'
import {
  PLATFORM_NICE_NAME_MAP,
  PLATFORM_CONNECTION_URL,
  YOUTUBE,
  TWITCH,
  STEAM,
  PLATFORM_NAME_ID_MAP,
} from 'config/settings'
import { availableCampaigns } from 'modules/campaign/actions'

import CreatorAvatar from './creator-avatar'
import styles from './styles.css'

const POOLING_DELAY_IN_MS = 5000 // 5 seconds pooling interval

export const getActiveCrawlingPlatforms = (user) =>
  Object.keys(PLATFORM_NAME_ID_MAP).filter(
    (platform) =>
      user.getIn(['profile', platform, 'username']) &&
      !user.getIn(['profile', platform, 'auth_invalid_at']) &&
      !user.getIn(['profile', platform, 'auth_flow_completed_at'])
  )

const isFlaggedInAllPlatforms = (user) => {
  const connectedPlatforms = [TWITCH, YOUTUBE].filter((platform) =>
    user.getIn(['profile', platform, 'username'])
  )
  const flaggedPlatforms = [TWITCH, YOUTUBE].filter((platform) =>
    user.getIn(['profile', platform, 'flagged_at'])
  )
  return connectedPlatforms.length && flaggedPlatforms.length === connectedPlatforms.length
}

export const AvatarContext = React.createContext({
  creator: null,
  setCreatorAvatar: () => {},
})

const mapStateToProps = ({ user, organizationInvitations, loading }) => ({
  user,
  organizationInvitations,
  isLoadingInvitationAccept: !!loading.get(ACCEPT_ORGANIZATION_INVITATION.ACTION),
  isLoadingInvitationDecline: !!loading.get(REMOVE_ORGANIZATION_INVITATION.ACTION),
  optinUnflag: user.getIn(['preferences', 'small_streamer_unflagged_emails']),
})

const mapDispatchToProps = {
  getUserByToken,
  acceptTerms,
  availableCampaigns,
  updateUserAccount,
  getPreferences,
  getOrganizationInvitations,
  acceptOrganizationInvitation,
  removeOrganizationInvitation,
  updateNewPreferences,
}

class AppUser extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    getUserByToken: PropTypes.func.isRequired,
    getPreferences: PropTypes.func.isRequired,
    getOrganizationInvitations: PropTypes.func.isRequired,
    acceptTerms: PropTypes.func.isRequired,
    acceptOrganizationInvitation: PropTypes.func.isRequired,
    removeOrganizationInvitation: PropTypes.func.isRequired,
    isLoadingInvitationAccept: PropTypes.bool.isRequired,
    isLoadingInvitationDecline: PropTypes.bool.isRequired,
    user: ImmutableProptypes.map,
    availableCampaigns: PropTypes.func.isRequired,
    organizationInvitations: ImmutableProptypes.list,
    updateUserAccount: PropTypes.func.isRequired,
    updateNewPreferences: PropTypes.func.isRequired,
    location: PropTypes.shape({
      query: PropTypes.shape({
        offset: PropTypes.string,
        hidden: PropTypes.string,
      }),
    }).isRequired,
    optinUnflag: PropTypes.bool,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  static defaultProps = {
    user: new Map(),
    organizationInvitations: new List(),
    optinUnflag: undefined,
  }

  state = {
    newEmail: '',
    creatorAvatar: null,
  }

  componentDidMount() {
    this.props.getUserByToken()
    this.props.getPreferences()
    this.checkNeedForPooling(this.props.user)
  }

  componentWillReceiveProps({ user, isLoadingInvitationAccept }) {
    this.checkNeedForPooling(user)

    const isPublisher = this.props.user.getIn(['profile', 'kind']) === KIND.PUBLISHER
    if (!this.didCheckOrganizations && isPublisher) {
      this.didCheckOrganizations = true
      this.props.getOrganizationInvitations()
    }

    if (!isLoadingInvitationAccept && this.props.isLoadingInvitationAccept) {
      // accepted entrying an organization, fully reload the page
      window.location.reload()
    }
  }

  componentDidUpdate() {
    if (
      browserHistory.getCurrentLocation().pathname !== '/missing-org' &&
      this.props.user.get('organization') === null &&
      this.props.user.getIn(['profile', 'kind']) === KIND.PUBLISHER
    ) {
      browserHistory.replace('/missing-org')
    }
  }

  componentWillUnmount() {
    clearTimeout(this.pooling)
    console.info('Unsetting user pooling')
  }

  setCreatorAvatar = (creator) => {
    this.setState({
      creatorAvatar: creator,
    })
  }

  checkNeedForPooling = (user) => {
    const isCreator = user.getIn(['profile', 'kind']) === KIND.CREATOR
    if (isCreator) {
      const isCrawlingAnyPlatform = !!getActiveCrawlingPlatforms(user).length
      if (isCrawlingAnyPlatform && !this.pooling) {
        console.info('Setting user pooling.')
        this.poolForUserData()
      }
      if (!isCrawlingAnyPlatform && this.pooling) {
        clearTimeout(this.pooling)
        console.info('Finshed user pooling')
        this.props.availableCampaigns(this.props.location.query)
      }
    }
  }

  didFetchOrganizations = false

  pooling = null

  poolForUserData() {
    clearTimeout(this.pooling)
    this.pooling = setTimeout(() => {
      this.props.getUserByToken()
      this.poolForUserData()
    }, POOLING_DELAY_IN_MS)
  }

  acceptTerms = () => {
    this.props.acceptTerms()
  }

  signout = () => {
    browserHistory.push('/signout')
  }

  handleSaveNewEmail = () => {
    this.props.updateUserAccount({
      profile: {
        creator: { email: this.state.newEmail },
        youtube_prompt_email_done: true,
      },
    })
  }

  handleCancelNewEmail = () => {
    this.props.updateUserAccount({
      profile: {
        youtube_prompt_email_done: true,
      },
    })
  }

  handleChangeNewEmail = ({ target }) => {
    this.setState({
      newEmail: target.value,
    })
  }

  acceptOrganizationInvitation = (token) => {
    this.props.acceptOrganizationInvitation(token)
  }

  declineOrganizationInvitation = (invitationId) => {
    this.props.removeOrganizationInvitation(invitationId)
  }

  optinUnflagEmail = () => {
    this.props.updateNewPreferences({
      small_streamer_unflagged_emails: true,
    })
  }

  dismissCreatorAvatar = () => {
    this.setCreatorAvatar(null)
  }

  creatorAvatar = {
    creatorAvatar: this.state.creatorAvatar,
    setCreatorAvatar: this.setCreatorAvatar,
  }

  render() {
    const {
      children,
      user,
      organizationInvitations,
      isLoadingInvitationAccept,
      isLoadingInvitationDecline,
      optinUnflag,
    } = this.props
    const { newEmail } = this.state
    const mustSignTerms = user.getIn(['profile', 'terms']) === false
    const needsReview = user.getIn(['profile', 'needs_review'])
    const hasOnlyFlaggedPlatforms = isFlaggedInAllPlatforms(user)
    const isCreator = user.getIn(['profile', 'kind']) === KIND.CREATOR
    const hasBadEmail = user.getIn(['profile', 'show_youtube_prompt']) === 'prompt'
    const platformsInCrawlingState = getActiveCrawlingPlatforms(user)
    const isCrawlingAnyPlatform = !!platformsInCrawlingState.length
    const hasKind = !!user.getIn(['profile', 'kind'])
    const mustReconnect = [TWITCH, YOUTUBE, STEAM]
      .map((platform) => !!user.getIn(['profile', platform, 'auth_invalid_at']) && platform)
      .filter(Boolean)[0]
    const hasTwitch = user.getIn(['profile', 'twitch', 'account_url'])
    const twitchScopes = user.getIn(['profile', 'twitch', 'scope']) || ''
    const isMissingNewTwitchScopes = hasTwitch && !twitchScopes.includes('moderator:read:followers')

    const stickyFooter = !!this.props.routes[this.props.routes.length - 1].stickyFooter

    return (
      <div className={styles.appPublisher}>
        <Header />

        {organizationInvitations.size ? (
          <div className={styles.pendingInvitation}>
            <div className={styles.pendingInvitationContent}>
              {organizationInvitations.map((invite) => (
                <div key={invite.hashCode()}>
                  <h3>
                    You have been invited to join{' '}
                    <strong>{invite.getIn(['organization', 'name'])}</strong> organization on
                    Woovit.{' '}
                  </h3>
                  <div className={styles.acceptOrDecline}>
                    {!(isLoadingInvitationAccept || isLoadingInvitationDecline) ? (
                      <>
                        <Button
                          onClick={this.acceptOrganizationInvitation}
                          onClickWith={invite.get('token')}
                          size="small"
                        >
                          Accept
                        </Button>{' '}
                        or{' '}
                        <Button
                          onClick={this.declineOrganizationInvitation}
                          onClickWith={invite.get('id')}
                          size="small"
                          kind="destructive"
                        >
                          Decline
                        </Button>
                      </>
                    ) : (
                      <div className={styles.loadingWrapper}>
                        <Loading isLoading />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {isCrawlingAnyPlatform && !mustReconnect ? (
          <div className={styles.crawlInProgress}>
            <div className={styles.crawlInProgressContent}>
              We are authenticating your{' '}
              {platformsInCrawlingState.map((p, i) => (
                <>
                  <div className={styles.crawlInProgressIcons}>
                    <Provider dark small platform={PLATFORM_NAME_ID_MAP[p]} />
                  </div>
                  {i + 1 < platformsInCrawlingState.length ? ' and ' : null}
                </>
              ))}{' '}
              account, hang tight!
            </div>
            <div className={styles.loader} />
          </div>
        ) : null}

        {!needsReview && hasOnlyFlaggedPlatforms && !isCrawlingAnyPlatform && isCreator ? (
          <div className={styles.needsReview}>
            Your account does not meet Woovit&apos;s minimum requirements. Keep working on your
            channel growth and check back soon.
            <br />
            {optinUnflag ? (
              <span className={styles.optinUnflag}>
                You will be notified by email when you become eligible.
              </span>
            ) : (
              <Button size="small" kind="primary" onClick={this.optinUnflagEmail}>
                Notify me by email when I become eligible
              </Button>
            )}
          </div>
        ) : null}

        {needsReview && isCreator ? (
          <div className={styles.needsReview}>
            Your account is flagged for review. We&apos;re monitoring the type of videos that you
            produce.
          </div>
        ) : null}

        {isMissingNewTwitchScopes && !mustReconnect && isCreator ? (
          <div className={styles.mustReconnect}>
            Your account is missing newly required Twitch scopes.{' '}
            <a className={styles.loginAgain} href={PLATFORM_CONNECTION_URL[TWITCH]}>
              Please log in again
            </a>{' '}
            to fix it.
          </div>
        ) : null}

        {mustReconnect && isCreator ? (
          <div className={styles.mustReconnect}>
            There&apos;s a connectivity issue with your {PLATFORM_NICE_NAME_MAP[mustReconnect]}{' '}
            account.{' '}
            <a className={styles.loginAgain} href={PLATFORM_CONNECTION_URL[mustReconnect]}>
              Please log in again
            </a>{' '}
            to fix it.
          </div>
        ) : null}

        <section className={styles.shutdown}>
          Alas, Woovit will shut down at 6 AM Eastern on Friday, December 20, 2024. Thanks to all
          the publishers and creators who have made Woovit possible the last seven years.
        </section>

        <AvatarContext.Provider value={this.creatorAvatar}>
          <div className={styles.content}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
        </AvatarContext.Provider>

        <ModalConfirm
          open={mustSignTerms && hasKind}
          onCancel={this.signout}
          cancelText="Signout"
          onConfirm={this.acceptTerms}
          confirmText="I Accept"
          title="Updates to our Terms of Service and Privacy Policy"
        >
          <p>
            Hi, we&apos;ve updated our{' '}
            <a target="_blank" rel="noopener noreferrer" href="/terms">
              Terms of Service
            </a>{' '}
            and{' '}
            <a target="_blank" rel="noopener noreferrer" href="/privacy-policy">
              Privacy Policy
            </a>
            , please accept them to continue with Woovit.
          </p>
        </ModalConfirm>

        <ModalConfirm
          open={hasBadEmail && isCreator}
          onCancel={this.handleCancelNewEmail}
          onConfirm={this.handleSaveNewEmail}
          onRequestClose={Function.prototype}
          confirmText="Add alternative email"
          title="May we have your email?"
        >
          <p>
            Sorry, we didn&apos;t get your email. That&apos;s fine but we won&apos;t be able to
            contact you. You may always add this later in your account settings.
          </p>
          <Field
            type="text"
            name="newEmail"
            placeholder="your nice email address"
            value={newEmail}
            onChange={this.handleChangeNewEmail}
          />
        </ModalConfirm>

        <CreatorAvatar slug={this.state.creatorAvatar} onDismiss={this.dismissCreatorAvatar} />
        <Footer sticky={stickyFooter} />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppUser)
